<template>
    <div class="authorization-page reset-password">
        <div class="form">
            <router-link :to="{name: 'Home'}">
                <img :src="require('@/assets/img/logo.png')" class="logo" alt="EURECBROKER">
            </router-link>

            <div v-if="!resetDone">

                <h1>{{ $t("Reset Password") }}</h1>

                <form @submit.prevent="forgotPassword">
                    <div class="form-group">
                        <input
                            type="text"
                            id="email"
                            name="email"
                            :placeholder="$t('Enter email e.g. john@example.com')"
                            v-model="credentials.email"
                            :class="['form-control', {'has-error': errors[`email`]}]"
                        >
                    
                    <FormErrors v-if="errors[`email`]" :errors="errors[`email`]"  />

                    </div>

                    <button type="submit" class="btn btn-primary w-100" :value="$t('Login')" :disabled="disabled">{{ $t("Reset my password") }}</button>
                    
                    <p class="already-an-account">{{ $t('Already a member?') }}
                        <router-link to="/login">{{ $t('Log In') }}</router-link>
                    </p>

                </form>

            </div>
            <div v-else class="reset-success">
                <h1>{{ $t("Reset Password Sent") }}</h1>
                <p class="text-center">{{ $t("Please check your email.") }}</p>
                <router-link :to="{ name: 'Login' }">
                    <button class="btn btn-primary">{{ $t('Sign In') }}</button>
                </router-link>
            </div>
        </div>
        <div class="side">
            <img :src="require('@/assets/img/background-authorization-page.png')" :alt="$t('RecBroker authorization image')" />
            <div class="circle black"></div>
            <div class="circle blue"></div>
        </div>
    </div>
</template>

<script>
import {userService} from "@/services/userService";
import FormErrors from "@/components/widgets/FormErrors";

export default {
    name: "ForgotPassword",
    components: {
        FormErrors
    },
    data() {
        return {
            credentials: {},


            resetDone: false,
        }
    },
    methods: {
        forgotPassword() {
            this.disabled = true
            this.errors = []

            userService.forgotPassword(this.credentials)
                .then(() => this.resetDone = true)
                .catch(error => this.errors = error.response.data.errors || [])
                .finally(() => this.disabled = false)
        }
    }
}
</script>

<style scoped>

</style>